<template>
  <b-card title="Actualizar Acción">
    <b-row>
      <b-col md="4">
        <label>Acción</label>
        <input
          type="text"
          v-model="form.descripcion"
          class="form-control form-control-sm"
          @keyup.enter="updateAction()"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" class="mt-3">
        <button
          class="btn btn-success btn-sm btn-circle"
          @click="updateAction()"
        >
          <i class="flaticon-edit" v-if="!loading"></i> {{ loading ? 'Cargando... ' : ''}}
        </button>
        <router-link
          class="btn btn-secondary btn-sm btn-circle ml-1"
          :to="{ name: 'actions' }"
        >
          <i class="flaticon2-reply"></i> Volver
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      form: {
        accion_id: this.$route.params.id,
        borrado: 0,
        descripcion: null,
      },
      descripcion: null,
    };
  },
  mounted() {
    this.loadAction();
  },
  methods: {
    loadAction() {
      axios
        .get(`/acciones/${this.id}`)
        .then((res) => {
          this.form = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateAction() {
      if (this.form.descripcion) {
        this.loading = true;
        axios
          .put(`acciones/${this.id}`, this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.$swal.fire({
                title: "Información !",
                text: res.data.information,
                icon: "success",
              });
              this.loadAction();
              this.loading = false;
            } else {
              toastr.info(res.data.information);
            }
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$swal.fire({
          title: "Información !",
          text: "Debe agregar una observación",
          icon: "warning",
        });
        this.loading = false;
      }
    },
  },
};
</script>
